import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout/old'
import PageBannerText from '../components/PageBannerText'

const BookConsultation = () => (
	<Layout style={{ paddingBottom: '1rem' }}>
		<Helmet>
			<title>Register with MineralSwim for Exclusive Pool Solutions</title>
			<meta
				name="description"
				content="Register your Mineral Swim system, get better support. Start making best use of your Mineral Swim."
			/>
			<meta name="keywords" content="register, registration" />
			<link
				rel="canonical"
				href="https://mineralswim.com/register/"
			/>
		</Helmet>
		<PageBannerText
			title={`Register`}
			subtitle={`Register your Mineral Swim system, get better support. Start making best use of your Mineral Swim.`}
		>
			<a
				className="register-button"
				href="https://maytronics.com.au/support/registration"
				rel="noopener noreferrer"
			>
				Register at Maytronics
			</a>
		</PageBannerText>
	</Layout>
)

export default BookConsultation
