import React from 'react'
import PropTypes from 'prop-types'

const PageBannerText = props => {
	const { title, title2, subtitle, params } = props
	const cta = params ? params.cta : ''
	return (
		<section className="pageTitle">
			<h1 className="title is-spaced">
				{cta === 'free-sample' ? 'Free Sample' : ''}
				{cta === 'talk-with-us' ? 'Talk With Us' : ''}
				{cta === 'get-info' ? 'More Info' : ''}
				{cta === 'switch-now' ? 'Switch Now' : ''}
				{!cta && title !== 'blank' ? title : ''}
				{!title ? 'Page Title' : ''}
			</h1>
			{title2 && (
				<h2 className="title is-size-4 is-spaced has-text-grey-dark">
					{title2 ? title2 : 'Page Title 2'}
				</h2>
			)}
			<div className="subtitle is-spaced">
				{cta === 'free-sample'
					? 'Fill out the form bellow to receive a free sample pack.'
					: ''}
				{cta === 'talk-with-us'
					? 'We would love to chat about how Mineral Swim could benefit you and your family today!'
					: ''}
				{cta === 'get-info'
					? 'Get more information about the Mineral Swim System.'
					: ''}
				{cta === 'switch-now'
					? 'Use this interactive tool to gain a better understanding of building or switching to a Mineral Swim pool.'
					: ''}
				{!cta ? subtitle : ''}
				{!subtitle ? 'Page Subtitle' : ''}
			</div>
			{props.children}
		</section>
	)
}

PageBannerText.propTypes = {
	title: PropTypes.any,
	title2: PropTypes.any,
	subtitle: PropTypes.any,
	params: PropTypes.object,
	children: PropTypes.node,
}

export default PageBannerText
